import React, { ComponentPropsWithoutRef, ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

import { SmallText } from '../smallText/SmallText'

const Number = ({ children }: { children: ReactNode }) => {
  return <b>{children}</b>
}

const Value = ({ children }: { children: ReactNode }) => {
  return <span>{children}</span>
}

type NumberedListItemProps = ComponentPropsWithoutRef<'li'>

const NumberedListItem = ({ className = '', children }: NumberedListItemProps) => {
  return (
    <li className={twMerge('py-2', className)}>
      <SmallText className="flex gap-x-2">{children}</SmallText>
    </li>
  )
}

NumberedListItem.Number = Number
NumberedListItem.Value = Value

type Props = ComponentPropsWithoutRef<'ol'>

const NumberedList = ({ className = '', children, ...props }: Props) => {
  return (
    <ol className={twMerge('divide-y divide-base-200', className)} {...props}>
      {children}
    </ol>
  )
}

NumberedList.Item = NumberedListItem

export { NumberedList }
