import React, { ComponentProps } from 'react'
import { twMerge } from 'tailwind-merge'

import { LabelText } from '../labelText/LabelText'

type Props = ComponentProps<'input'> & {
  inputClassName?: string
  label?: string
  showClose?: boolean
}

export const Checkbox = ({
  className = '',
  inputClassName = '',
  label,
  value,
  disabled,
  onChange,
  ...props
}: Props) => {
  return (
    <div className={twMerge('flex w-full max-w-sm gap-2', className)}>
      {label && <LabelText htmlFor={label}>{label}</LabelText>}

      <input
        className={twMerge('checkbox-primary checkbox', inputClassName)}
        type="checkbox"
        id={label || undefined}
        value={value}
        disabled={disabled}
        onChange={onChange}
        {...props}
      />
    </div>
  )
}
