import React, { ComponentPropsWithoutRef } from 'react'
import { twMerge } from 'tailwind-merge'

type Props = ComponentPropsWithoutRef<'header'>

export const Headerbar = ({ className = '', children, ...props }: Props) => {
  return (
    <header className={twMerge('navbar bg-base-100 p-0 lg:px-8', className)} {...props}>
      {children}
    </header>
  )
}
