import React, { ComponentPropsWithoutRef } from 'react'
import { twMerge } from 'tailwind-merge'

type Props = ComponentPropsWithoutRef<'label'>

export const LabelText = ({ className = '', children, ...props }: Props) => {
  return (
    <label className={twMerge('label-text', className)} {...props}>
      {children}
    </label>
  )
}
