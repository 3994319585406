import React, { ComponentProps } from 'react'
import { twMerge } from 'tailwind-merge'

import BuiltWithSubIcon from '../../assets/built-with-sub.svg'

type Props = ComponentProps<'a'>

export const BuiltWithSub = ({ className = '', ...props }: Props) => {
  return (
    <a
      href="https://getsub.dev?ref=built-with-sub"
      aria-label="SUB website"
      className={twMerge('btn btn-neutral', className)}
      {...props}
    >
      <BuiltWithSubIcon />
    </a>
  )
}
