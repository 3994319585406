import React, { ComponentProps } from 'react'
import { twMerge } from 'tailwind-merge'

import { HeadingText } from '../headingText/HeadingText'
import { SmallText } from '../smallText/SmallText'

type Props = {
  Icon: any
  title: string
  description: string
} & ComponentProps<'div'>

export const BlankState = ({ className = '', Icon, title, description, children, ...props }: Props) => {
  return (
    <div
      className={twMerge('mx-auto flex max-w-lg flex-1 flex-col items-center justify-center gap-y-6', className)}
      {...props}
    >
      <Icon className="h-16 w-16 text-base-content" />

      <div className="flex flex-col gap-y-2 text-center">
        <HeadingText>{title}</HeadingText>

        <SmallText>{description}</SmallText>
      </div>

      {children}
    </div>
  )
}
