import React, { ComponentProps } from 'react'
import { twMerge } from 'tailwind-merge'

type Props = ComponentProps<'a'>

export const AnchorText = ({ className = '', children, ...props }: Props) => {
  return (
    <a
      className={twMerge('link-hover link link-primary font-semibold', className)}
      target="_blank"
      rel="noopener noreferrer"
      {...props}
    >
      {children}
    </a>
  )
}
