import React, { ComponentPropsWithoutRef, useCallback } from 'react'
import { twMerge } from 'tailwind-merge'

import { LabelText } from '../labelText/LabelText'

export type SelectOption = {
  value: string | number
  label: string
  disabled?: boolean
}

type Props = ComponentPropsWithoutRef<'div'> & {
  label?: string
  placeholder?: string
  value?: string | number
  options: SelectOption[]
  disabled?: boolean
  onValueChange: (option: SelectOption) => void
}

export const Select = ({
  className = '',
  label,
  placeholder,
  value,
  options,
  disabled,
  onValueChange,
  ...props
}: Props) => {
  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const selectedOption = options.find(option => option.value === event.target.value)

      if (selectedOption) {
        onValueChange(selectedOption)
      }
    },
    [onValueChange, options],
  )

  return (
    <div className={twMerge('flex flex-col gap-y-2', className)} {...props}>
      {label && <LabelText htmlFor={label}>{label}</LabelText>}

      <select
        className="select select-bordered w-full max-w-sm focus:outline-primary"
        disabled={disabled}
        onChange={onChange}
      >
        {placeholder && !value && (
          <option disabled selected>
            {placeholder}
          </option>
        )}

        {options.map(option => (
          <option key={option.value} value={option.value} selected={option.value === value} disabled={option.disabled}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  )
}
