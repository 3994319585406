import React, { ComponentProps } from 'react'
import { twMerge } from 'tailwind-merge'

import { LabelText } from '../labelText/LabelText'

type Props = ComponentProps<'textarea'> & { label?: string }

export const TextArea = ({ className = '', label, ...props }: Props) => {
  return (
    <div className={twMerge('flex flex-col gap-y-2', className)}>
      {label && <LabelText htmlFor={label}>{label}</LabelText>}

      <textarea
        className="textarea textarea-bordered w-full max-w-sm text-sm focus:outline-primary"
        id={label || undefined}
        {...props}
      />
    </div>
  )
}
