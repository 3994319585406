import { ComponentProps, ReactNode } from 'react'
import React from 'react'
import { twMerge } from 'tailwind-merge'

import { Button } from '../button/Button'
import { Logo } from '../logo/Logo'

type SidebarItemProps = {
  active?: boolean
} & ComponentProps<typeof Button>

const SidebarItem = ({ active, ...props }: SidebarItemProps) => {
  return <Button className={twMerge('w-full justify-start', active ? 'btn-neutral' : 'btn-ghost')} {...props} />
}

type Props = {
  children?: ReactNode
}

const Sidebar = ({ children }: Props) => {
  return (
    <div className="flex flex-1 flex-col gap-y-6 overflow-y-auto bg-base-200 p-6">
      <div className="flex items-center">
        <Logo />
      </div>

      <nav className="flex flex-1 flex-col">
        <ul className="-mx-2 flex flex-1 flex-col space-y-1">{children}</ul>
      </nav>
    </div>
  )
}

Sidebar.Item = SidebarItem

export { Sidebar }
