export const config = {
  name: 'SUB',
  description: 'The ultimate React + Firebase boilerplate to surface hard and surface fast!',
  shortDescription: 'Surface Hard! Surface Fast!',
  emails: {
    transactional: '',
    support: 'shaun@getsub.dev',
  },
  links: {
    twitter: 'https://x.com/_shaunsaker',
    github: 'https://github.com/shaunsaker',
    linkedIn: 'https://www.linkedin.com/in/shaunsaker/',
  },
  features: {
    customEmails: false,
    requireVerifiedEmails: false,
    subscriptions: false,
  },
}
