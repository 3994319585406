import React, { ComponentPropsWithoutRef } from 'react'
import { twMerge } from 'tailwind-merge'

type Props = ComponentPropsWithoutRef<'p'>

export const TinyText = ({ className = '', children, ...props }: Props) => {
  return (
    <small className={twMerge('block text-xs font-normal text-base-content', className)} {...props}>
      {children}
    </small>
  )
}
