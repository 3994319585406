import React, { ComponentProps } from 'react'
import { twMerge } from 'tailwind-merge'

import { LabelText } from '../labelText/LabelText'

type Props = Omit<ComponentProps<'input'>, 'ref'> & {
  inputClassName?: string
  inputRef?: React.RefObject<HTMLInputElement>
  label?: string
  showClose?: boolean
}

export const FileInput = ({
  className = '',
  inputClassName = '',
  inputRef,
  label,
  value,
  disabled,
  onChange,
  ...props
}: Props) => {
  return (
    <div className={twMerge('flex flex-col gap-y-2', className)}>
      {label && <LabelText htmlFor={label}>{label}</LabelText>}

      <input
        className={twMerge(
          'file-input file-input-bordered w-full max-w-sm text-sm focus:outline-primary',
          inputClassName,
        )}
        ref={inputRef}
        id={label || undefined}
        type="file"
        value={value}
        disabled={disabled}
        onChange={onChange}
        {...props}
      />
    </div>
  )
}
