import React, { ComponentPropsWithoutRef, ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

import { SmallText } from '../smallText/SmallText'

const Label = ({ children }: { children: ReactNode }) => {
  return <b>{children}</b>
}

const Value = ({ children }: { children: ReactNode }) => {
  return <span>{children}</span>
}

type ListItemProps = ComponentPropsWithoutRef<'li'>

const ListItem = ({ className = '', children }: ListItemProps) => {
  return (
    <li className={twMerge('py-2', className)}>
      <SmallText className="flex justify-between gap-x-2">{children}</SmallText>
    </li>
  )
}

ListItem.Label = Label
ListItem.Value = Value

type Props = ComponentPropsWithoutRef<'ul'>

const List = ({ className = '', children }: Props) => {
  return <ul className={twMerge('divide-y divide-base-200', className)}>{children}</ul>
}

List.Item = ListItem

export { List }
