import React, { ComponentProps, ComponentPropsWithoutRef } from 'react'
import { twMerge } from 'tailwind-merge'

type NavbarItemProps = {
  active?: boolean
} & ComponentProps<'a'>

const NavbarItem = ({ active, ...props }: NavbarItemProps) => {
  return <a role="tab" className={twMerge('tab', active ? 'tab-active' : '')} {...props} />
}

type Props = ComponentPropsWithoutRef<'div'>

const Navbar = ({ className = '', ...props }: Props) => {
  return <div role="tablist" className={twMerge(`tabs-boxed tabs`, className)} {...props} />
}

Navbar.Item = NavbarItem

export { Navbar }
